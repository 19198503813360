/* GENERAL */

.volume-label:hover {
  cursor: default;
  color: inherit;
}

input[type="range"].volume-bar {
  margin-bottom: 45px;
  transform: rotate(270deg);
  -moz-transform: rotate(270deg); /*do same for other browsers if required*/
  width: 80px;
}

/* SLIDER STYLING */
/* Reset styling */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ffffff;
  border-radius: 1.5px;
  height: 8px;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -1px; /* Centers thumb on the track */
  background-color: var(--red);
  border-radius: 2.5px;
  height: 10px;
  width: 30px;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline: 3px solid var(--red);
  outline-offset: 0.125rem;
}

/*********** Firefox styles ***********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: #ffffff;
  border-radius: 1.5px;
  height: 8px;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  background-color: var(--red);
  border: none; /*Removes extra border that FF applies*/
  border-radius: 2.5px;
  height: 10px;
  width: 30px;
}

input[type="range"]:focus::-moz-range-thumb {
  outline: 2px solid var(--red);
  outline-offset: 0.125rem;
}

/* DESKTOP */
@media (min-width: 601px) {
  .progress-bar {
    margin-top: 10px;
  }

  .volume-label,
  .volume-bar {
    display: initial;
  }

  input[type="range"].volume-bar {
    margin-bottom: initial;
    transform: none;
    -moz-transform: none; /*do same for other browsers if required*/
    width: initial;
  }
}

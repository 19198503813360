/* Profile.css */
.modal-content {
    background-color: #334A6A;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px; 
    width: 60%;
    text-align: center;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal-content form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-field {
    margin-bottom: 15px;
    width: 100%;
}

.form-field label {
    margin-bottom: 5px;
    font-weight: bold;
    display: block;
}

.form-field input,
.form-field textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.form-field textarea {
    height: 80px; /* Adjust the height as needed */
    resize: vertical;
}

.submit-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-field label::before {
    content: " *";
    color: red;
}

.submit-button[disabled] {
    background-color: #ccc; /* Grey color for disabled state */
    cursor: not-allowed; /* Change cursor for disabled state */
  }
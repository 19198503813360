/* GLOBAL STYLES */

/* VARIABLES */
:root {
    --font-common: 'Quicksand', Verdana, sans-serif;
    --font-highlight: 'Rubik', Verdana, sans-serif;
    --background: #2e2b2d;
    --foreground: #fffbf4;
    --yellow: #FFE561;
    --red: #EB5482;
    --blue: #49A5E9;
    --orange: #FEAF00;
    --curved: 20px;
    --doubled: 1rem double;
    --stagedive-gradient: linear-gradient(180deg, #FEAF00, #FEAF00, #EF1C00, #198FE7, #198FE7);
}

.background-blue {
    background-color: var(--blue);
}

.background-red {
    background-color: var(--red);
}

/* FONTS */
@import url('https://fonts.googleapis.com/css?family=Quicksand|Rubik');

html {
    font-size: 16px;
    font-family: var(--font-common);
    scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-highlight);
}

p {
    font-family: var(--font-common);
}

a {
    text-decoration: none;
    color: var(--foreground);
}

input {
    font-size: 14px;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* BODY + GENERAL */
body {
    color: var(--foreground);
    background-color: var(--background);
    margin: 0;
}

pre {
    overflow: auto;
}

/* COMPONENTS */


label {
    font-weight: bold;
}

/* Style Icons/Icon buttons */
.material-symbols-rounded {
	font-size: 24px;
	cursor: pointer;
	color: var(--foreground);
	padding: 5px;
	transition: color 0.2s; /* Add a smooth transition effect for color change */
}

.material-symbols-rounded:hover {
  	color: var(--yellow); /* Change the color when hovering */
}

.future-update {
    color: var(--red);
}
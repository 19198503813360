.album-view-container {
    padding: 20px;
}

.albums-info {
    display: flex;
}
.albums-info img {
    height:70px;
    width: 70px;
    margin: 0;
}

.songs-details {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    justify-content: space-between;
}

.track-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column; 
    gap: 10px;
}

.track-item {
    padding: 10px;
    /* background-color: #f0f0f0;  */
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.track-item:hover {
    background-color: #717171;
}

/* Mobile styles */
@media (max-width: 767px) {
        .album-view-container {
            font-size: 16px;
        }
}
  
/* Desktop styles */
@media (min-width: 768px) {
    .album-view-container {
        /* padding: 20px; */
        max-width: 40%;
        display: block; 
        margin: 0 auto;
    }
    
    .track-list {
        width: 100%; 
    }
    
}
  
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply scroll-m-20 text-4xl font-extrabold tracking-tight;
  }
}

/* App */
.App {
  display: flex;
  flex-direction: column;
  /* width: 100vw; */
  height: 100vh;
  scrollbar-gutter: stable;

}

.fill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin: 0 10vw;
}

.sessionButton {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 17px;
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
}

/* Supertokens Email Spam notice */
#supertokens-root::after {
  content: "**For all emails from StageDive, please remember to check your spam folder.";
  display: block;
  margin: auto;
  padding: 50px;
  max-width: 600px;
  text-align: center;
  color: white;
}

/* shadcn/ui Base Utility Classes */


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 24 9.8% 10%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 20 14.3% 4.1%;
    --radius: 0.5rem;
    --pf: 262 80% 43%;
    --sf: 316 70% 43%;
    --af: 175 70% 34%;
    --in: 198 93% 60%;
    --su: 158 64% 52%;
    --wa: 43 96% 56%;
    --er: 0 91% 71%;
    --inc: 198 100% 12%;
    --suc: 158 100% 10%;
    --wac: 43 100% 11%;
    --erc: 0 100% 14%;
    --rounded-box: 1rem;
    --rounded-btn: 0.5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: 0.25s;
    --animation-input: .2s;
    --btn-text-case: uppercase;
    --btn-focus-scale: 0.95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: 0.5rem;
    --p: 262 80% 50%;
    --pc: 0 0% 100%;
    --s: 316 70% 50%;
    --sc: 0 0% 100%;
    --a: 175 70% 41%;
    --ac: 0 0% 100%;
    --n: 213 18% 20%;
    --nf: 212 17% 17%;
    --nc: 220 13% 69%;
    --b1: 212 18% 14%;
    --b2: 213 18% 12%;
    --b3: 213 18% 10%;
    --bc: 220 13% 69%;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: #fffbf4;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 60 9.1% 97.8%;
    --primary-foreground: 24 9.8% 10%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 24 5.7% 82.9%;
  }
}


@layer base {
  * {
    @apply border-border;
  }
  body {
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

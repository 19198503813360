#landing-page .hero-image {
    position: relative;
    height: 300px;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 50px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#landing-page .hero-image.slide-1 {
    background-image: url('../../assets/images/landing.avif');
}

#landing-page .hero-image.slide-2 {
    background-image: url('../../assets/images/landing3.avif');
}

@media (min-width: 768px) {
    #landing-page .hero-image {
		padding: 50px;
    }
}
  
.hero-text {
    font-size: 36px;
    text-shadow: 2px 2px 4px #000;
}
  
.sign-up-button {
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: auto;
    display: inline-block;
}
  
.sign-up-button:hover {
    background-color: #333;
    color: #ffcc00;
}
  
.slick-slider {
    position: relative;
}
  
.slick-dots {
    position: absolute;
    background-color: #ffcc00;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}
  
.slick-dots li {
    margin: 0 5px;
}
  
.slick-dots button {
    font-size: 16px;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
}
  
.slick-dots button:hover {
    background-color: #fff;
}

.slick-arrow {
    display: none !important;
}
  
.image-side {
    height: auto; 
    border-radius: 10px;
	object-fit: cover;
    object-position: 68%;
}
  
.text-side {
    flex: 1;
    padding: 20px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.socialproof {
    flex: 1;
    max-width: 300px; 
    margin: 0 10px; 
}

.divider {
    height: 1px;
    background-color: #FFCC01; 
    margin: 20px 0; 
}

.x-tweets {
    margin-top: 20px;
    padding: 20px;
    justify-content: center;
    align-items: center;
}

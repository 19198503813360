.music-card-list {
    display: flex; /* Use flexbox */
    flex-wrap: nowrap; /* Prevent cards from wrapping to the next line */
    padding: 10px 0; /* Add some spacing */
    overflow-x: auto; /* Add horizontal scroll if cards overflow */
    scrollbar-width: thin; /* Only works on Firefox */
}

/* Thin scrollbar on Chrome, Edge, Safari */
.music-card-list::-webkit-scrollbar {
    height: 9px;
}

.music-card-list::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
}

/* Hide scroll bar on screens < tablet size */
@media (max-width: 768px) {
    .music-card-list {
      -ms-overflow-style: none;  /* Hide Scrollbar - IE and Edge */
      scrollbar-width: none;  /* Hide Scrollbar - Firefox */
    }
    .music-card-list::-webkit-scrollbar {
        display: none;
    }
}

.music-card {
    background-color: #434040;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px; /* Increase padding for larger size */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    cursor: pointer; /* Add pointer cursor on hover */
    transition: background-color 0.3s; /* Add smooth background color transition */
    margin: 0 10px;
    max-width: 150px;
}
  
.music-card:hover {
    background-color: #5e5e5e; /* Light grey color on hover */
}
  
.music-card img {
    width: 120px; /* Increase image size */
    height: 120px; /* Increase image size */
    margin-bottom: 10px; /* Add spacing between image and song details */
}
  
.music-details {
    display: flex;
    flex-direction: column;
    text-align: center;
}
  
.song-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Limit to three lines */
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 13px;
    font-weight: bold;
}

.artist {
    margin: 0;
    font-size: 12px;
}
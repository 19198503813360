.app-container * {
  box-sizing: border-box;
}

.bold-400 {
  font-variation-settings: "wght" 400;
}

.bold-500 {
  font-variation-settings: "wght" 500;
}

.bold-600 {
  font-variation-settings: "wght" 600;
}

.bold-700 {
  font-variation-settings: "wght" 700;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.separator-line {
  max-width: 100%;
}

.link .link-icon {
  width: 15px;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .separator-line {
    max-width: 200px;
  }
}

@media screen and (max-width: 480px) {
  .app-container .main-container {
    margin-block-end: 90px;
  }
}

#tracks-leaderboard {
  --mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0) 0
  );
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

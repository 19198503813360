@keyframes bounce-once {
    0% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    25% {
        transform: translateY(-10px);
        animation-timing-function: ease-in;
    }
    50% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    75% {
        transform: translateY(-5px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
}
  
.animate-bounce-once {
animation: bounce-once 1s;
}
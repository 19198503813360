section.support-questions {
	margin: auto;
	max-width: 800px;
}

/* Accordion Container */
.accordion-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* FAQ Item Styles */
.faq-item {
    width: 80%;
    margin: 10px;
    padding: 15px;
    background-color: #5567A6; 
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s;
}
  
.faq-item:hover {
    background-color: #5567A6;
}
  
/* FAQ Question Styles */
.faq-question {
    font-weight: bold;
    display: flex;
    align-items: center;
}
  
.plus-minus-icon {
    margin-right: 10px;
}
  
/* FAQ Answer Styles */
.faq-answer {
    margin-top: 10px;
    display: none;
}
  
/* Active State Styles */
.faq-item.active {
    background-color: #794E7E;
}
  
.faq-item.active .faq-answer {
    display: block;
}
  
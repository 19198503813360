.track-view-container {
  padding: 2%;
}

/* Mobile styles */
@media (max-width: 767px) {
  .track-view-container {
    font-size: 16px;
  }
}

/* Desktop styles */
@media (min-width: 768px) {
  .left-side {
    width: 55%;
    height: auto;
    float: left;
  }

  .left-side > * {
    max-width: 250px;
    margin: auto;
  }

  .left-side img {
    height: auto;
    border-radius: 10px;
  }

  /* Styles for the right side */
  .right-side {
    width: 45%;
    height: 100vh;
    float: right;
    overflow-y: auto;
  }

  /* Clearfix to prevent float elements from overlapping */
  .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }
}

/* HERO STYLES */
#hero {
    text-align: center;
    height: 400px;
    padding-top: 0;
    background-image: url('../../assets/images/stage.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.sd-backdrop-filter {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    backdrop-filter: brightness(20%);
    /* box-shadow: 0 0 50px var(--background) inset; */
}

#page-heading {
    font-size: 2.5rem;
    margin: 0;
}

#hero span {
    font-size: 1.545rem;
}

/* CONTENT */
.content-column {
    max-width: 600px;
    margin: auto;
    padding: 0 50px 50px;
}

.content-column h1, #about-container section h2 {
    text-align: center;
    margin: 50px;
}

.view-card {
    border: 2px solid var(--yellow);
    border-radius: 15px;
    margin: 50px -15px;
    padding: 15px;
}

.view-select {
    display: flex;
    justify-content: center;
    gap: 10px;
}

#listeners {
    display: none;
}

#creators .center {
    text-align: center;
    margin: 10px auto;
}

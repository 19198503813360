#analytics-tables table, #analytics-tables tr, #analytics-tables th, #analytics-tables td {
    background-color: transparent;
    background-image: none;
}

#analytics-tables table {
    font-family: inherit;
}

#analytics-tables th {
    font-weight: 700;
    border: none;
}

#analytics-tables td {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--blue);
    border-right: none;
    text-align: center;
}

#analytics-tables td:nth-child(1) {
    text-align: left;
}
/* NAV BAR */
nav#main-menu {
  display: flex;
  height: 70px;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: -1px;
  z-index: 11;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
}

.logo,
.logo a {
  display: flex;
  height: 100%;
}

.logo img {
  height: 70%;
  width: auto;
  margin: auto;
  padding-left: 0.618rem;
  padding-right: 0.618rem;
}

.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5%;
}

.nav-links a {
  color: var(--foreground);
}

.nav-links .search.tooltip-bottom:after {
  border-color: transparent transparent var(--red) transparent;
}

.nav-links .search.tooltip-bottom::before {
  border: 1px solid var(--red);
  background-color: var(--background);
}

.nav-links .profile-link {
  padding: 0;
  vertical-align: middle;
}

/* MAIN */
main {
  margin-top: 70px;
  min-height: calc(100vh - 70px);
}

/* FEEDBACK FORM */
#feedback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 10px;
  background-color: transparent;
  z-index: 3;
}

#feedback-form.show {
  height: 100%;
  width: 100vw;
}

#feedback-form iframe {
  display: none;
  background-color: white;
}

#feedback-form.show iframe {
  position: relative;
  display: block;
  width: 100vw;
  max-width: 650px;
  height: 75vh;
  border: 1px solid var(--yellow);
  z-index: 100;
}

#feedback-form.show .overlay {
  background-color: rgba(46, 43, 45, 0.8);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

/* FOOTER LINKS */
.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-links a {
  display: inline-block;
  margin-right: 1rem;
}

.music-section {
    margin: 50px 0;
}

.music-section h2 {
    margin: 0;
}

.play-playlist {
    color: var(--yellow);
    padding: 0 5px;
    vertical-align: middle;
}
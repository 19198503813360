/* CONTENT */
#add_to_home-container .content-column ol li {
    margin-bottom: 15px;
}

#add_to_home-container span.material-symbols-rounded {
    font-size: inherit;
}

#add_to_home-container #android {
    display: none;
}

#add_to_home-container .bug-report-icon {
    background-color: var(--red);
    border-radius: 50%;
    border: 2px solid white;
    cursor: default;
}

.success-checkout {
    text-align: center;
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 50px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #18181B;
}

.success-checkout h1 {
    font-size: 22px;
    color: #4CAF50;
}

.success-checkout p {
    font-size: 18px;
    margin: 10px 0;
}

.success-checkout button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.success-checkout button:hover {
    filter: brightness(120%);
}